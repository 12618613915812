<template>
    <setting-layout :title="$t('pages.user.setting.block_regions.title')">
        <v-container>
            <v-row v-for="(item, index) in countries" :key="index" align="center">
                <v-col cols="2">
                    <img :src="require(`@/assets/images/flags/${index.toLowerCase()}.png`)" :alt="item" class="flags"/>
                </v-col>
                <v-col cols="6">
                    {{ item }}
                </v-col>
                <v-spacer/>
                <v-col cols="2">
                    <v-checkbox
                        hide-details
                        dense
                        class="pt-0 ma-0"
                        color="orange darken-3"
                        v-model="model"
                        :checked="model.indexOf(index.toLowerCase()) !== 0"
                        :value="index.toLowerCase()"
                    />
                </v-col>
            </v-row>
        </v-container>

    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import countries from '@/configs/countries.js'
import {mapActions, mapState} from "vuex";
export default {
    name: "BlockRegions",
    data: () => ({
        countries,
        model: []
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        })
    },
    watch: {
        model(val) {
            this.setBlockedRegions({
                model: {
                    blocked_countries: val
                }
            })
            // do some stuff
        }
    },
    methods: {
        ...mapActions(['getBlockedRegions', 'setBlockedRegions'])
    },
    mounted() {
        this.getBlockedRegions().then(() => {
                this.model = this.$store.state.user.user.blockedRegions
            }
        )

        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Block regions');
    },
    components: {SettingLayout}
}
</script>

<style scoped>
.flags {
    border-radius: 3px;
    width: 30px;
}
</style>
